<template>
  <div>
    <b-modal size="md" hide-footer v-model="modalCopyPrice" class="w-100" title="Copiar Precios">
      <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
      <b-row>
        <b-col md="6">
          <b-form-group label="Copiar Desde:">
            <b-form-select :options="establishsment_from" v-model="product_price.id_establishment_from"></b-form-select>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="Copiar A:">
            <b-form-select :options="establishsment_to"  v-model="product_price.id_establishment_to"></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>


      <b-row>
        <b-col md="4"> </b-col>
        <b-col md="4"> <b-button class="form-control" variant="primary" type="submit"><i class="fas fa-save"></i> Copiar Precio</b-button> </b-col>
      </b-row>

      </b-form>
    </b-modal>

    <LoadingComponent :is-visible="isLoading"/>

  </div>
</template>
<style>


</style>



<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import LoadingComponent from './../../pages/Loading'

export default {
  name: "ModalsProduct",
   components:{
    LoadingComponent,
  },
  data() {
    return {
        isLoading:false,
        modalCopyPrice:false,
        module:'ProductPrice',
        role:3,
        establishsment_from: [],
        establishsment_to: [],
        product_price: {
          id_establishment_from:'',
          id_establishment_to:'',
        },
        errors:{
          price:false,
        }
    };
  },
  created (){
    
  },
  mounted () {
    EventBus.$on('ModalCopyPriceShow', () => {
      this.isLoading = false;
      this.modalCopyPrice = true;
      this.product_price.id_establishment_from = '';
      this.product_price.id_establishment_to = '';
      this.ListEstablishment();
    });
    
  },
  methods: {
      ListEstablishment,
      Validate,
      CopyProductPrice,
    
  },
  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_establishment: function () {
      let establishment = window.localStorage.getItem("id_establishment");
      establishment = JSON.parse(je.decrypt(establishment));
      return establishment;
    }
  },
};

function ListEstablishment() {
  
  let me = this;
  let url = this.url_base + "active-establishments";
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module, role:this.role,},
  })
    .then(function (response) {
      me.establishsment_from = [{ value:'', text:'- Seleccione un establecimiento -'}];
      me.establishsment_to = [{ value:'', text:'- Seleccione un establecimiento -'}];
      if (response.data.status == 200) {
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
          me.establishsment_from.push({ value: element.id_establishment, text:  element.name});
          me.establishsment_to.push({ value: element.id_establishment, text:  element.name});
        }
      }
      me.isLoading = false;
    })

    
}



//Buscar productos
function CopyProductPrice() {

  let me = this;
  let url = me.url_base + "product/copy-price-by-establishment";
  let data = me.product_price;
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { token: me.token, module: me.module, role:me.role,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        EventBus.$emit('ListPriceProduct');
        me.modalCopyPrice = false;
        me.isLoading = false;
        Swal.fire({ icon: 'success', text: 'Se ha copiado los precios de los productos', timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}

function Validate() {

  if (this.product_price.id_establishment_from == "") {
    Swal.fire({ icon: 'warning', text: 'Seleccione el establecimiento de origen', timer: 2000,}); 
    return false;
  }
  if (this.product_price.id_establishment_to == "") {
    Swal.fire({ icon: 'warning', text: 'Seleccione el establecimiento de destino', timer: 2000,}); 
    return false;
  }
  if (this.product_price.id_establishment_to == this.product_price.id_establishment_from) {
    Swal.fire({ icon: 'warning', text: 'Los establecimientos no pueden ser iguales', timer: 2000,}); 
    return false;
  }
  
  Swal.fire({
    title: 'Esta seguro de copiar los precios?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, Estoy de Acuerdo!'
  }).then((result) => {
    if (result.isConfirmed) {
      this.CopyProductPrice();
    }
  })
}
</script>
